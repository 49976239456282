.player-question-display-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.player-question-display-question-and-answer {
  padding-bottom: 1em;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.player-question-display-response-zone {
}

.multiple-choice-display-option-container {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
