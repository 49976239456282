.question-list-image-container {
  width: 60px;
  height: 60px;
  margin-top: 0.25em;
  background-color: #f5f5f5;
}

.question-list-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
