.image-input-preview-container {
  width: 100px;
  height: 100px;
  background-color: #f5f5f5;
}

.image-input-preview-img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}
