.event-dashboard-mobile-button-split {
  display: none;
}

@media only screen and (max-width: 767px) {
  .event-dashboard-table-header {
    display: none !important;
  }
  .event-dashboard-mobile-button-split {
    display: block;
    height: 0.5em;
  }
}
