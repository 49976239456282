.game-play-container-panel {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1400px;
  min-height: 90vh;
  max-height: 1200px;
  flex-direction: column;
  justify-content: space-around;
  padding: 2.5em;
  margin-top: 0.5em;
}

.game-play-host-row {
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
  padding-bottom: 10px;
}

.game-play-controls-container {
  position: 'relative';
  flex: 1;
  max-width: 600px;
  min-width: 400px;
  padding: 1.5em 2em 1.5em 2em;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  margin-left: 2em;
  margin-right: 2em;
}

.game-play-controls {
  flex: 1;
  height: 100%;
  /* position: absolute;
  display: flex;
  width: 100%;
  max-width: 600px;
  height: 100%;
  max-height: 315px;
  z-index: 1;
  background: white;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #eaeaea; */
}

.game-play-players-row {
  /* justify-content: space-between; */
  padding-top: 2em;
  padding-bottom: 4px;
  display: flex;
  overflow-x: auto;
}

.game-play-player {
  /* margin-right: 2em; */
}

@media only screen and (max-width: 1050px) {
  .game-play-container-panel {
    padding: 8px;
  }
  .game-play-controls-container {
    margin-left: 8px;
    margin-right: 8px;
  }
}
