.live-leader-board {
  overflow-y: auto;
  padding: 1.5em 2em 2em 2em;
  border-radius: 4px;
  border: 1px solid #eaeaea;
}

.live-leader-board-mini {
  max-height: 320px;
}

.live-leader-board-player-name {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.live-leader-board-row-heading {
  white-space: nowrap;
}

img.live-leader-board-avatar {
  width: 52px !important;
}

@media only screen and (max-width: 1050px) {
  .live-leader-board-mini {
    padding: 8px;
  }
}
