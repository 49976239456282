.player-card {
  margin: 1px !important;
  border-width: 2px !important;
  /* width: 240px !important; */
  /* width: 105px !important; */
}

.player-card.player-has-responded-correctly {
  border-color: #21ba45;
}

.player-card.player-has-responded {
  border-color: #ec0a68;
}

.player-card-name-display {
  display: flex;
}

.player-card-name-display-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
