.quizbee-help-container {
  z-index: 1;
  position: absolute;
  bottom: -10px;
  right: 0;
  cursor: pointer;
}

.quizbee-help-container {
  height: 80px;
  width: 80px;
}
