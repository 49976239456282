.host-question-display-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.host-question-display-question-and-answer {
  padding-bottom: 1em;
  /* paddingTop: '2em',
          paddingBottom: '2em',
          display: 'flex',
          justifyContent: 'space-evenly',
          flexDirection: 'column', */
}
