.home-container-panel {
  display: flex;
  justify-content: space-around;
}

.home-container-panel-1 {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 3em 2em 3em 2em;
}

.home-container-panel-1 img {
  margin-top: 2em;
  margin-bottom: 1em;
  max-width: 320px;
}

.home-container-panel-2 {
  height: 400px;
  width: 400px;
  align-self: center;
  padding-left: 2em;
  padding-right: 2em;
}

@media only screen and (max-width: 767px) {
  .home-container {
    min-height: inherit;
  }
  .home-container-panel {
    margin-top: 2em;
    flex-direction: column;
    align-items: center;
    min-width: inherit;
  }
  .home-container-panel-1 {
    align-items: center;
  }
}

.home-container-panel-authorised-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-container-panel-authorised-user img {
  margin-top: 2em;
  margin-bottom: 1em;
  max-width: 320px;
}
