.video-content-container {
  display: flex;
  overflow: hidden;
}

.video-content-container .video-container video {
  width: 100%;
  object-fit: cover;
}

.video-content-container .video-placeholder-image {
}

.video-content-container img.video-placeholder-image {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.video-content-container img.video-placeholder-image-micro {
  width: 40px;
  border-radius: 4px;
}

.video-content-container .override-msg {
  padding: 0.3em;
  color: red;
  background-color: #ffffffe0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  text-align: center;
  text-transform: uppercase;
  font-size: small;
}
