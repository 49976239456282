.add-edit-event-container {
  /* add additional padding to match the padding of the tab label */
  padding-left: 6px;
  padding-bottom: 6px;
}
.add-edit-event-action-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
}
