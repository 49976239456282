.sound-player-controls-loader {
  padding-left: 4px;
  align-items: center;
  margin-top: 1em;
}

.sound-player-controls-loader .loader:before {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.sound-player-controls-loader .loader:after {
  border-color: #767676 transparent transparent !important;
}

.sound-player-controls.error {
  color: red;
  align-items: center;
}

.sound-player-controls {
  display: flex;
  margin-top: 1em;
}

.sound-player-progress-container {
  flex: 1;
  margin: auto;
}

.sound-player-progress {
  border-radius: 100px !important;
  margin: 0 !important;
}

.sound-player-progress .bar {
  border-radius: 100px !important;
  height: 18px !important;
  background: #ec0a68 !important;
}

.not-playing .sound-player-progress .bar {
  opacity: 0;
}

.playing .sound-player-progress .bar {
  opacity: 1;
}
