/* date-picker style overrides */
.CalendarMonth_caption {
  margin-bottom: 14px;
}
.CalendarDay__selected {
  background: #ec0a68;
  border: #ec0a68;
  color: white;
}
.CalendarDay__selected:hover {
  background: #c401f5;
  border: #c401f5;
  color: white;
}
.SingleDatePickerInput {
  border-radius: 4px !important;
}
.SingleDatePickerInput .DateInput {
  border-radius: 4px !important;
}
.SingleDatePickerInput input {
  border: none !important;
  border-radius: 4px !important;
}
.error .SingleDatePickerInput {
  border-color: #ec0a68 !important;
}
