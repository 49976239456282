.game-play-basic-container-panel.content-panel-container-panel {
  position: relative;
  display: flex;
  max-width: 1400px;
  min-height: 80vh;
  max-height: 1200px;
  padding: 2.5em;
  margin-top: 0.5em;
  justify-content: center;
  min-width: 1280px;
}

.game-play-basic-controls {
  padding: 1.5em 2em 1.5em 2em;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  max-width: 800px;
  margin-right: 1em;
  flex: 1;
  align-self: flex-start;
}

.game-play-basic.content-panel-container {
  min-height: inherit;
}

@media only screen and (max-width: 980px) {
  .game-play-basic.content-panel-container {
    padding: 1em 0 0 0;
  }
  .game-play-basic-container-panel.content-panel-container-panel {
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 0 5em 0;
  }
  .game-play-basic-controls {
    width: 100%;
    max-width: inherit;
    flex: none;
    margin-bottom: 2em;
    margin-right: 0;
    border: none;
    padding: 1em;
  }
  .game-play-basic-container-panel .live-leader-board {
    border: none;
    padding: 1em;
  }
}

@media only screen and (max-width: 1280px) {
  .game-play-basic-container-panel.content-panel-container-panel {
    min-width: 100%;
  }
}
