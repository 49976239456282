.upgrade-time {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}

.upgrade-time-image {
  max-height: 60vh;
}

.upgrade-time-action-box {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.upgrade-time-action-text {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.upgrade-time-action-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
