.question-image-container {
  margin-top: 1em;
}

.question-image {
  opacity: 0;
  width: 100%;
  max-width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
