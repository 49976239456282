.content-panel-container {
  /* padding: 2em; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 100vh;
  padding-top: 2em;
  padding-bottom: 2em; */
}

.content-panel-container-panel {
  background: white;
  /* border-radius: 1em; */
  min-width: 90%;
  min-height: 500px;
  padding: 2em;
}

.content-panel-sub-content {
  background: white;
  margin-top: 1em;
  padding: 0.5em 1em 0.5em 1em;
}

@media only screen and (max-width: 767px) {
  .content-panel-container {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .content-panel-container-panel {
    margin-top: 1em;
  }
}

@media only screen and (max-width: 1050px) {
  .content-panel-container {
    padding: 8px;
  }
  .content-panel-container-panel {
    margin-top: 1em;
  }
}
