.under-maintenance {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.under-maintenance-content {
  max-width: 600px;
  padding: 30px 10px 40px 10px;
}

.under-maintenance-message {
  padding: 20px 10px 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
